import {mapActions, mapGetters} from 'vuex';
import Vue from "vue/dist/vue";
import {updateState} from "../../../../../../common/Vue/VueHelper";
import {trans} from "../../../../../../common/Translation/Translation";
import $ from "jquery";
import {ske} from "../../../../../../common/Ske/Ske";

export default Vue.component('IdentityDocumentForm', {
    data() {
        return {
            fileFront: null,
            fileFrontType: null,
            fileBack: null,
            fileBackType: null
        };
    },

    /**
     * Local properties of the components.
     * They are obtained from the Vue template.
     *
     * If you define a "user" property, then in your template, you should have:
     * <my-component :user="..."> [...] </my-component>
     *
     * @property {array}
     */
    props: ['participant'],

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapGetters({
            findByParticipantUidx: 'identityDocument/findByParticipantUidx',
            isGuest: 'participantCollection/isGuest',

            isTypeIdentityCard: 'identityDocument/isTypeIdentityCard',
            isTypePassport: 'identityDocument/isTypePassport'
        }),

        /*
         * parent() and guest() are defined because there are needed because of the prototype of the parent.
         */
        parent() {
            return this.$parent.parent || null;
        },

        guest() {
            return (this.isGuest(this.participant))
                ? this.participant
                : null;
        },

        identityDocument() {
            return this.findByParticipantUidx(this.participant.uidx);
        },

        type() {
            return this.identityDocument.type;
        },

        number() {
            return this.identityDocument.number;
        },

        lastNames() {
            return this.identityDocument.lastNames;
        },

        firstNames() {
            return this.identityDocument.firstNames;
        },

        nationality() {
            return this.identityDocument.nationality;
        },

        dateOfIssue() {
            return this.identityDocument.dateOfIssue;
        },

        dateOfExpiry() {
            return this.identityDocument.dateOfExpiry;
        },

        dateOfBirth() {
            return this.participant.dateOfBirth;
        },
        filenameFront() {
            return this.identityDocument.filenameFront;
        },
        filenameBack() {
            return this.identityDocument.filenameBack;
        },

        /**
         *
         * @returns {boolean}
         */
        areAllInputsDisplayed() {
            return this.isTypeIdentityCard(this.identityDocument) || this.isTypePassport(this.identityDocument);
        },
        isFileDocumentFrontDisplayed() {
            return this.isTypeIdentityCard(this.identityDocument) || this.isTypePassport(this.identityDocument);
        },
        isFileDocumentBackDisplayed() {
            return this.isTypeIdentityCard(this.identityDocument);
        },
        getWarningMessage() {
            if (this.type === null) {
                return null;
            }

            return trans('form.participant.identity-document.type.' + this.type + '.warning', {
                '%contact%': ske().parameters.siteReferentCompleteName,
                '%email%': ske().parameters.siteReferentEmail
            });
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions({
            update: 'identityDocument/update'
        }),

        getTypeFromExtension(ext) {
            ext = ext.toLowerCase();

            if (['png', 'jpg', 'jpeg'].includes(ext)) {
                return 'image';
            } else if (ext === 'pdf') {
                return 'pdf';
            }

            return 'other';
        },

        getTypeFromMime(mime) {
            mime = mime.toLowerCase();

            if (mime.includes('image')) {
                return 'image';
            } else if (mime.includes('pdf')) {
                return 'pdf';
            }

            return 'other';
        },

        hasFileFrontPreview() {
            return ['image', 'pdf'].includes(this.fileFrontType);
        },

        hasFileBackPreview() {
            return ['image', 'pdf'].includes(this.fileBackType);
        },

        /**
         * Get the value from the event, then pass it to the update action.
         *
         * @param {string} prop
         * @param {Event} event
         */
        updateState(prop, event) {
            updateState(this.identityDocument, prop, event, this.update);
        },

        updateFileData(prop, def, event) {
            if (event === null) {
                this[prop] = this[def];

                if (this[def] !== null) {
                    this[prop + 'Type'] = this.getTypeFromExtension(this[def].split('.')[1]);
                }

                return;
            }

            const files = event.target.files;

            this[prop] = this[def];

            if (files.length > 0) {
                let file = files[0];

                this[prop + 'Type'] = this.getTypeFromMime(file.type);

                const reader = new FileReader();

                reader.onload = e => {
                    this[prop] = e.target.result;
                };

                reader.readAsDataURL(file);
            }
        },

        removeFile(prop) {
            this[prop] = null;

            // Empty <input> file value.
            $(this.$refs[prop + 'Input']).val(null);
        }
    },

    mounted() {
        this.updateFileData('fileFront', 'filenameFront', null);
        this.updateFileData('fileBack', 'filenameBack', null);
    }
})

