var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen",
        },
      ],
      staticClass: "overlay-modal",
      on: { click: _vm.close },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen",
          },
        ],
        staticClass: "room-modal",
      },
      [
        _c("div", { staticClass: "room-modal-header" }, [
          _c("div", { staticClass: "room-modal-fixed-title" }, [
            _c("h4", { staticClass: "u-txt-uppercase" }, [
              _vm._v(_vm._s(_vm._f("trans")("form.rooming.modal.title"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "room-modal-close",
              attrs: { type: "button" },
              on: { click: _vm.close },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    fill: "none",
                    height: "33",
                    viewBox: "0 0 24 24",
                    width: "33",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "m16 8-8 8m.00001-8 7.99999 8",
                      stroke: "#000",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.5",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "room-modal-content" },
          [
            _vm.hasMoreThanOneHotel
              ? _c("p", {
                  staticClass: "help",
                  domProps: {
                    innerHTML: _vm._f("trans")(
                      "form.rooming.modal.help-multiple"
                    ),
                  },
                })
              : _c("p", {
                  staticClass: "help",
                  domProps: {
                    innerHTML: _vm._f("trans")("form.rooming.modal.help"),
                  },
                }),
            _vm._v(" "),
            _c("div", { staticClass: "select-label" }, [
              _c("label", [
                _vm._v(
                  _vm._s(_vm._f("trans")("form.rooming.occupants")) + " :"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                multiple: "",
                placeholder: _vm._f("trans")(
                  "form.rooming.modal.occupants.select.placeholder"
                ),
                options: _vm.getSelectableParticipants(),
                selectable: _vm.isSelectable,
                value: _vm.occupants,
                label: "_vSelectLabel",
              },
              on: {
                "option:selecting": _vm.selectParticipant,
                "option:deselecting": _vm.deselectParticipant,
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function (option) {
                    return [_vm._v(_vm._s(_vm.getCompleteName(option)))]
                  },
                },
                {
                  key: "no-options",
                  fn: function () {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")(
                            "form.rooming.modal.occupants.select.noMoreOccupant"
                          )
                        )
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _vm.isFormEditMode
              ? _c("div", { staticClass: "room-modal-premium" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-item-choice-container" }, [
                    _c("div", { staticClass: "form-item-choice" }, [
                      _c("input", {
                        attrs: {
                          id: "is-asking-for-premium-0",
                          type: "radio",
                          name: "is-asking-for-premium",
                          value: "0",
                        },
                        domProps: { checked: _vm.isAskingForPremium === false },
                        on: { change: _vm.selectIsAskingForPremium },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "is-asking-for-premium-0" } },
                        [_vm._v("Hébergement classique")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-item-choice" }, [
                      _c("input", {
                        attrs: {
                          id: "is-asking-for-premium-1",
                          type: "radio",
                          name: "is-asking-for-premium",
                          value: "1",
                        },
                        domProps: { checked: _vm.isAskingForPremium },
                        on: { change: _vm.selectIsAskingForPremium },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "is-asking-for-premium-1" } },
                        [_vm._v("Hébergement Premium / Deluxe")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _vm.isAskingForPremium
                    ? _c("div", { staticClass: "room-modal-premium-message" }, [
                        _c("p", { staticClass: "bold text-upp" }, [
                          _vm._v("Supplément tarifaire :"),
                        ]),
                        _vm._v(" "),
                        _vm._m(2),
                        _vm._v(" "),
                        _vm._m(3),
                        _vm._v(" "),
                        _vm._m(4),
                        _vm._v(" "),
                        _vm._m(5),
                        _vm._v(" "),
                        _vm._m(6),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n                        Pour toute question, merci de contacter "
                          ),
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(
                              _vm._s(
                                _vm.ske().parameters.siteReferentCompleteName
                              )
                            ),
                          ]),
                          _vm._v(" au\n\n                        "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "tel:" +
                                  _vm.ske().parameters.siteReferentPhoneNumber,
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.ske().parameters.siteReferent[
                                      "phone-number"
                                    ]
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n\n                        ou par mail à\n\n                        "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:" +
                                  _vm.ske().parameters.siteReferentEmail,
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.ske().parameters.siteReferentEmail
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(".\n                    "),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasMoreThanOneHotel
              ? _c("div", { staticClass: "room-modal-hotels" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm._f("trans")("form.rooming.modal.hotel.label"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      attrs: { name: "hotel" },
                      on: { change: _vm.selectHotel },
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: { disabled: "" },
                          domProps: { selected: _vm.hotel === null },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("trans")(
                                "form.rooming.modal.hotel.placeholder"
                              )
                            ) + "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.hotels, function (hotelChoice) {
                        return _c(
                          "option",
                          {
                            attrs: {
                              disabled: _vm.hotelIsDisabled(hotelChoice),
                            },
                            domProps: {
                              value: hotelChoice.id,
                              selected:
                                _vm.hotel && hotelChoice.id === _vm.hotel.id,
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.getHotelLabel(hotelChoice)) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "room-form" }, [
              _vm.roomTypes
                ? _c(
                    "div",
                    { staticClass: "form-item typeOfRoom" },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")(
                              "form.rooming.modal.room-type.label"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.roomTypes, function (roomTypeChoice) {
                        return _c("div", [
                          _c("div", { staticClass: "form-item-choice" }, [
                            _c("input", {
                              attrs: {
                                type: "radio",
                                name: "room-type",
                                id: "room-type" + roomTypeChoice.id,
                                disabled:
                                  _vm.isRoomTypeDisabled(roomTypeChoice),
                              },
                              domProps: {
                                value: roomTypeChoice.id,
                                checked:
                                  _vm.roomType &&
                                  roomTypeChoice.id === _vm.roomType.id,
                              },
                              on: { change: _vm.selectRoomType },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: { for: "room-type" + roomTypeChoice.id },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.getRoomTypeLabel(roomTypeChoice, {
                                        withDetails: true,
                                      })
                                    ) +
                                    "\n\n                                "
                                ),
                                _vm._v(" "),
                                roomTypeChoice.price.priceInCents > 0
                                  ? _c("span", [
                                      _c("span", [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(
                                              _vm._f("trans")(
                                                "form.rooming.modal.room-type.prix",
                                                {
                                                  "%prix%": Intl.NumberFormat(
                                                    "de-DE",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  ).format(
                                                    roomTypeChoice.price
                                                      .priceInCents / 100
                                                  ),
                                                }
                                              )
                                            ) +
                                            "\n                          "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "room-submit-container" }, [
              _c(
                "button",
                {
                  staticClass: "submit c-button c-button-secondary",
                  attrs: { type: "button", disabled: _vm.isSubmitDisabled() },
                  on: { click: _vm.submit },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("trans")("form.rooming.modal.submit")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [_c("b", [_vm._v("Type d'hébergement :")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "room-modal-premium-message" }, [
      _c("p", [
        _c("span", { staticClass: "text-upp" }, [
          _vm._v("Chambre Classique :"),
        ]),
        _vm._v("\n                        18 - 24m"),
        _c("sup", [_vm._v("2")]),
        _vm._v(
          ", 1 lit Queen size de 160 ou deux lits de 90 collés.\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { staticClass: "text-upp" }, [
        _vm._v("Chambre "),
        _c("b", [_vm._v("Premium")]),
        _vm._v(" :"),
      ]),
      _vm._v("\n                        24m"),
      _c("sup", [_vm._v("2")]),
      _vm._v(", lits de 180 cm"),
      _c("br"),
      _vm._v(
        "\n                        Tarif : à partir de 68€ de supplément par nuitée en single, et 77€ de supplément par nuitée en double et twin."
      ),
      _c("br"),
      _vm._v(
        "\n                        Sur demande et sous réserve de disponibilité au moment de la confirmation.\n                    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { staticClass: "text-upp" }, [
        _vm._v("Chambre "),
        _c("b", [_vm._v("Deluxe")]),
        _vm._v(" :"),
      ]),
      _vm._v("\n                        26 - 30 m"),
      _c("sup", [_vm._v("2")]),
      _c("br"),
      _vm._v(
        "\n                        Disponible uniquement en chambre double (1 lit pour deux personnes) ou en single (1 grand lit de 180cm)."
      ),
      _c("br"),
      _vm._v(
        "\n                        Tarif : à partir de 85€ de supplément par nuitée en single, et 94€ de supplément par nuitée en double ("
      ),
      _c("b", [_vm._v("catégorie de chambre non disponible en twin")]),
      _vm._v(")."),
      _c("br"),
      _vm._v(
        "\n                        Sur demande et sous réserve de disponibilité au moment de la confirmation.\n                    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { "margin-top": "30px" } }, [
      _c("b", [
        _vm._v(
          "\n                            En cochant cette case, vous faites une demande d'information pour un hébergement Premium\n                            / Deluxe."
        ),
        _c("br"),
        _vm._v(
          "\n                            Vous recevrez un mail avec les disponibilités à jour.\n                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v('\n                        Les demandes de chambres "'),
      _c("b", [_vm._v("Premium")]),
      _vm._v('" et "'),
      _c("b", [_vm._v("Deluxe")]),
      _vm._v(
        "\" seront satisfaites sous réserve\n                        de disponibilités jusqu'à la validation par mail de l'agence Ici La Terre."
      ),
      _c("br"),
      _vm._v(
        "\n                        En cas d'indisponibilité, une chambre classique est confirmée à la réservation.\n                    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n                        Le supplément pour un hébergement supérieur ("
      ),
      _c("b", [_vm._v("Premium / Deluxe")]),
      _vm._v(
        ") fera l'objet d'une\n                        facturation complémentaire (hors inscription à la convention).\n                    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }