import {trans, Translation} from "../../../../../common/Translation/Translation";
import Vue from "vue";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * Use the VuexState#create method to define your state:
     *     VuexState.create({ ... })
     *
     * The "create" static method needs one object argument, containing all property names as keys,
     * and property object as values.
     *
     * Property objects should have a "type" property. They could also have two facultative properties:
     * - "formatter", which must have the name of one of the methods of the FieldFormatter class.
     * - "default", which is the default value of the property.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        _globalParticipantState: () => ({
            id: null,
            idx: null,
            uidx: null,
            civility: null,
            firstName: null,
            lastName: null,
            dateOfBirth: null,
            email: null,
            mobileNumber: null,
            remarks: null,
            activity: null,
            canBeRemoved: true,
            isParticipating: true,
            seatId: null
        }),
        _participantState: (state) => ({
            ...state._globalParticipantState(),
            type: 'participant',
            currentGuestIdx: 0,
        }),
        _guestState: (state) => ({
            ...state._globalParticipantState(),
            type: 'guest'
        }),

        all: [],
        currentParticipantIdx: 0
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        /**
         * Tell whether there is at least one participant.
         *
         * @param {object} state
         * @param {object} getters
         * @returns {boolean}
         */
        hasParticipants(state, getters) {
            return (getters.countParticipants > 0);
        },

        /**
         * Count the number of total participants.
         *
         * @param {object} state
         * @returns {number}
         */
        countParticipants(state) {
            return state.all.length;
        },

        /**
         * Get all participants which are not guest.
         *
         * @param {object} state
         * @param {object} getters
         * @returns {array}
         */
        getNotGuestParticipants(state, getters) {
            return state.all.filter(p => !getters.isGuest(p));
        },

        /**
         * Get all participants which are not guest.
         *
         * @param {object} state
         * @param {object} getters
         * @returns {array}
         */
        getGuestParticipants(state, getters) {
            return state.all.filter(p => getters.isGuest(p));
        },
        /**
         * Get all participants without room.
         *
         * @param {VuexState} state
         * @param {object} rootState
         * @param {object} getters
         * @param {array} rootGetters
         * @returns {array}
         */
        getParticipantsWithoutRoom(state, getters, rootState, rootGetters) {
            const participants = state.all;

            let participantsWithoutRoom = [];

            for (const participant of participants) {
                const isWithoutRoom = (rootGetters["roomCollection/findRoomByParticipant"](participant) === null);

                if (isWithoutRoom) {
                    participantsWithoutRoom.push(participant);
                }
            }

            return participantsWithoutRoom;
        },
        /**
         * Get whether all participants are within a room.
         *
         * @param {object} state
         * @param {object} getters
         * @returns {boolean}
         */
        areAllParticipantsInRoom(state, getters) {
            return (getters.getParticipantsWithoutRoom.length === 0);
        },
        /**
         * Find a participant by its ID.
         *
         * @param {VuexState} state
         * @returns {function(number): object}
         */
        getParticipantById: (state) => (id) => {
            return state.all.find(p => (p.id === id));
        },
        /**
         * Find a participant by its IDX (ID within the form AND its parent).
         *
         * @param {VuexState} state
         * @returns {function(number): object}
         */
        getParticipantByIdx: (state) => (idx) => {
            return state.all.find(p => (p.idx === idx));
        },
        /**
         * Find a participant by its UIDX (global ID within the form).
         *
         * @param {VuexState} state
         * @returns {function(string): object}
         */
        getParticipantByUidx: (state) => (uidx) => {
            return state.all.find(p => (p.uidx === uidx));
        },
        /**
         * Whether the participant is a guest or not.
         *
         * @returns {function(object): boolean}
         */
        isGuest: () => (participant) => {
            return (participant.type === 'guest');
        },
        /**
         * Whether the participant has at least one guest or not.
         *
         * @param {VuexState} state
         * @param {object} getters
         * @returns {function(object): boolean}
         */
        hasGuest: (state, getters) => (participant) => {
            if (getters.isGuest(participant)) {
                return false;
            }

            return (getters.getGuests(participant).length > 0);
        },
        /**
         * Whether the participant is the first of the list.
         *
         * @param {VuexState} state
         * @param {object} getters
         * @returns {function(object): boolean}
         */
        isFirstParticipant: (state, getters) => (participant) => {
            return (!getters.isGuest(participant) && participant.idx === 0);
        },
        /**
         * Get the complete name of the participant.
         *
         * @returns {function(object): string}
         */
        getCompleteName: () => (participant) => {
            if (participant.civility === null && participant.lastName === null && participant.firstName === null) {
                return null;
            }

            return [
                participant.civility,
                participant.firstName,
                participant.lastName
            ].join(' ');
        },
        /**
         * Get the full complete name of the participant.
         *
         * @returns {function(object): string}
         */
        getCompleteNameTwo: () => (participant) => {
            if (participant.civility === null || participant.lastName === null || participant.firstName === null) {
                return null;
            }

            return [
                participant.civility,
                participant.firstName,
                participant.lastName
            ].join(' ');
        },
        /**
         * Get the translated label of the participant type.
         *
         * @returns {function(object): string}
         */
        getTypeLabel: () => (participant) => {
            return trans('entity.participant.type.' + participant.type + '.label');
        },
        /**
         * Get a guest by its parent and its IDX.
         *
         * @param {VuexState} state
         * @param {object} getters
         * @returns {function(object, number): ?object}
         */
        getGuestByIdx: (state, getters) => (participant, idx) => {
            if (getters.isGuest(participant)) {
                return null;
            }

            return state.all.find(guest => guest.idx === idx);
        },
        /**
         * Get the IDX of the guest's parent.
         *
         * @param {VuexState} state
         * @param {object} getters
         * @returns {function(object): ?number}
         */
        getParentIdx: (state, getters) => (guest) => {
            if (!getters.isGuest(guest)) {
                return null;
            }

            const arr = guest.uidx.split(':');

            return +arr[0];
        },
        /**
         * Get the guest's parent.
         *
         * @param {VuexState} state
         * @param {object} getters
         * @returns {function(object): object}
         */
        getParent: (state, getters) => (guest) => {
            return getters.getParticipantByIdx(getters.getParentIdx(guest));
        },
        /**
         *
         * @param state
         * @param getters
         * @returns {(function(*): (null|T[]))|*}
         */
        getGuests: (state, getters) => (participant) => {
            if (getters.isGuest(participant)) {
                return null;
            }

            return state.all.filter(g => (getters.getParentIdx(g) === participant.idx));
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        load({rootState, state, dispatch}) {
            const data = rootState.data.data;

            if (data) {
                const {participants} = data;

                for (const participant of participants) {
                    const guests = participant.guests;

                    delete participant.guests;

                    dispatch('addParticipant', { data: participant });
                    participant.idx = (state.currentParticipantIdx - 1);

                    for (const guest of guests) {
                        dispatch('addGuest', { parent: participant, data: guest });
                    }
                }
            }
        },
        addParticipant({state, getters, commit, dispatch}, {data = {}}) {
            const
                idx = state.currentParticipantIdx,
                uidx = idx.toString(),
                identityDocument = data.identityDocument || {},
                transport = data.transport || {},
                seatId = data.seat?.id
            ;

            delete data.identityDocument;
            delete data.transport;
            delete data.seat;

            data = {
                ...state._participantState(state),
                ...data,
                idx,
                uidx,
                seatId,
                _vSelectLabel: getters.getCompleteName(data),
                canBeRemoved: (idx > 0)
            }

            return new Promise((resolve, reject) => {
                commit('addParticipant', {data});

                dispatch('identityDocument/add', {
                    data: {
                        ...identityDocument,
                        participantUidx: uidx
                    }
                }, {root: true});

                dispatch('transport/add', {
                    data: {
                        ...transport,
                        participantUidx: uidx
                    }
                }, {root: true});

                resolve(data);
            });
        },
        addGuest({commit, getters, dispatch, state}, {parent, data = {}}) {
            
            const
                idx = parent.currentGuestIdx,
                // idx = data.id || parent.currentGuestIdx,
                uidx = parent.idx + ':' + idx,
                identityDocument = data.identityDocument || {},
                transport = data.transport || {},
                seatId = data.seat?.id
            ;

            delete data.identityDocument;
            delete data.transport;
            delete data.seat;

            data = {
                ...state._guestState(state),
                ...data,
                idx,
                uidx,
                seatId,
                _vSelectLabel: getters.getCompleteName(data)
            }

            commit('addGuest', {parent, data});

            dispatch('identityDocument/add', {
                data: {
                    ...identityDocument,
                    participantUidx: uidx
                }
            }, {root: true});

            dispatch('transport/add', {
                data: {
                    ...transport,
                    participantUidx: uidx
                }
            }, {root: true});
        },
        update({state, commit}, {data}) {
            data._vSelectLabel = data.civility + ' ' + data.firstName + ' ' + data.lastName;
            commit('update', {data});
        },
        remove({commit}, {uidx}) {
            // This action is captured by removeFromCollectionPlugin.
            // The mutations are called from there.
        },
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        addParticipant(state, {data = {}}) {
            state.all.push(data);

            Vue.set(state, 'currentParticipantIdx', state.currentParticipantIdx + 1);
        },
        addGuest(state, {parent, data = {}}) {

            state.all.push(data);

            Vue.set(parent, 'currentGuestIdx', parent.currentGuestIdx + 1);
        },
        update(state, {data}) {
            const index = state.all.findIndex(p => (p.uidx === data.uidx));

            for (const prop in data) {
                Vue.set(state.all[index], prop, data[prop]);
            }
        },
        remove(state, {uidx}) {
            const index = state.all.findIndex(p => (p.uidx === uidx));

            state.all.splice(index, 1)
        },
        /**
         * Remove a guest from the state by its parent's IDX and own IDX.
         *
         * @param {VuexState} state
         * @param {number} parentIdx
         * @param {number} guestIdx
         * @returns {void}
         */
        removeGuestFromParticipant(state, {parentIdx, guestIdx}) {
            const
                parent = state.all.find(item => (item.idx === parentIdx)),
                guestIndex = parent.guests.findIndex(item => (item.idx === +guestIdx))
            ;

            parent.guests.splice(guestIndex, 1)
        },
        /**
         * Update participant (parent) data.
         *
         * @param {VuexState} state
         * @param {number} participantIdx
         * @param {string} prop
         * @param {*} value
         * @returns {void}
         */
        updateParticipant(state, {participantIdx, prop, value}) {
            /** @var {VuexState} participant */
            const participant = state.all.find(p => (p.idx === participantIdx));

            participant.update({[prop]: value});
        },
        /**
         * Update guest data.
         *
         * @param {VuexState} state
         * @param {number} parent
         * @param {number} guestIdx
         * @param {string} prop
         * @param {*} value
         * @returns {void}
         */
        updateGuest(state, {parentIdx, guestIdx, prop, value}) {
            const
                parent = state.all.find(p => (p.idx === parentIdx)),
                /** @var {VuexState} guest */
                guest = parent.guests.find(g => (g.idx === guestIdx))
            ;

            guest.update({[prop]: value});
        }
    },
}
