<template>
  <div v-if="registration.isParticipating" class="recap-block">
    <div class="recap-block-header">
      <h3>{{ 'form.recap.title'|trans }}</h3>
    </div>

    <div class="recap-block-content">

      <div class="recap-block-container">
        <p class="recap-block-date">
                    <span
                        v-if="agency.name === null"
                        :inner-html.prop="'form.recap.date.default'|trans({'%date%': new Date().toLocaleDateString('fr')})">
                    </span>

          <span
              v-else-if="agency.name"
              :inner-html.prop="'form.recap.date.withAgency'|trans({'%date%': new Date().toLocaleDateString('fr'), '%agency%': agency.name})">
                    </span>
        </p>

        <div class="recap-block-content">

          <div class="recap-block-agency" v-if="agency.name && displayCompleteInformation">
            <div class="recap-block-agency-wrapper">
              <div class="recap-block-agency-title">
                <div class="bold">
                  <span class="recap-bold">{{ 'form.recap.agency.title'|trans }}</span>
                </div>
              </div>

              <div class="recap-block-agency-complete-infos">
                <div v-if="agency.name" class="recap-block-agency-name"
                     :inner-html.prop="'form.recap.agency.name'|trans({'%name%': agency.name})">
                </div>

                <div v-if="agency.type" class="recap-block-agency-type"
                     :inner-html.prop="'form.recap.agency.type'|trans({'%type%': trans('entity.agency.type.' + agency.type + '.label')})">
                </div>

                <!-- div v-if="agency.corporateName" class="recap-block-agency-corporate-name"
                     :inner-html.prop="'form.recap.agency.corporateName'|trans({'%corporateName%': agency.corporateName})">
                </div -->
              </div>

            </div>
          </div>

          <form-recap-participant v-if="participants.length > 0"></form-recap-participant>
          <form-recap-room v-if="rooms.length > 0"></form-recap-room>

          <div class="total-registration-price">
            <h3>TOTAL de la commande = {{
                Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(getRegistrationTTCTotal())
              }} TTC</h3>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import FormRecapParticipant from "./Participant/FormRecapParticipant";
import FormRecapRoom from "./Room/FormRecapRoom";
import {trans} from "../../../../common/Translation/Translation";

export default {
  /**
   * Vue components inside this one.
   *
   * @property {object}
   */
  components: {
    FormRecapRoom,
    FormRecapParticipant
  },

  /**
   * Local data of the components.
   *
   * @property {array}
   */
  data: function () {
    return {
      displayCompleteInformation: true,
      displayParticipantCompleteInformation: false
    }
  },

  /**
   * All computed properties of the component.
   *
   * @property {object}
   */
  computed: {
    /*
     * Import properties of states:
     *
     * ...mapState(['namespace/property'])
     * ...mapState('namespace', ['property', 'property'])
     * ...mapState({
     *     propertyAlias: state => state.namespace.property,
     *     propertyAlias: state => state.namespace.property
     * })
     *
     * Then use them:
     * - in Vue templates: {{ property }} or {{ propertyAlias }}
     * - in here: this.property or this.propertyAlias
     *
     * Import getters of modules:
     *
     * ...mapGetters(['namespace/getter'])
     * ...mapGetters('namespace', ['getter', 'getter'])
     * ...mapGetters({
     *     getterAlias: 'namespace/getter',
     *     getterAlias: 'namespace/getter'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
     * - in here: this.getter() or this.getterAlias()
     */
    ...mapState({
      registration: state => state.registration,
      agency: state => state.agency,
      participants: state => state.participantCollection.all,
      rooms: state => state.roomCollection.all
    }),
    ...mapGetters({
      findByActivity: 'availabilities/findByActivity',
      findParticipationPrice: 'availabilities/findParticipationPrice',

      // From module roomTypes.
      findRoomTypeById: 'roomTypes/findById'
    })
  },

  /**
   * All methods of the component.
   *
   * @property {object}
   */
  methods: {
    /*
     * Import actions of modules:
     *
     * ...mapActions(['namespace/action'])
     * ...mapActions('namespace', ['action', 'action'])
     * ...mapActions({
     *     actionAlias: 'namespace/action',
     *     actionAlias: 'namespace/action'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ action() }} or {{ actionAlias() }}
     * - in here: this.action() or this.actionAlias()
     *
     * Import mutations of modules:
     *
     * ...mapMutations(['namespace/mutation'])
     * ...mapMutations('namespace', ['mutation', 'mutation'])
     * ...mapMutations({
     *     mutationAlias: 'namespace/mutation',
     *     mutationAlias: 'namespace/mutation'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
     * - in here: this.mutation() or this.mutationAlias()
     */

    trans,

    getRegistrationTTCTotal() {
      let total_activity_prices = 0;
      let total_room_prices = 0;
      let total_registration_price = 0;

      const participant_participation = this.findParticipationPrice();
      const total_participation_price = this.participants.length * participant_participation;

      total_registration_price += total_participation_price;

      for (const participant of this.participants) {
        if (participant.activity) {
          const activity = this.findByActivity(participant.activity.id);
          total_activity_prices += activity.price.priceInCents
        }
      }

      total_registration_price += total_activity_prices;

      for (const room of this.rooms) {
        const
            roomType = this.findRoomTypeById(room.roomTypeId),
            roomPrice = roomType.price.priceInCents,
            roomExtraPrice = roomType.supplement.priceInCents
        ;

        total_room_prices += roomPrice + roomExtraPrice;
      }

      total_registration_price += total_room_prices;

      return total_registration_price / 100;
    }
  }
};
</script>
