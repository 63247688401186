var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDisplayed()
    ? _c("div", { staticClass: "recap-block-participant-activities" }, [
        _c("h5", [_vm._v("Activité :")]),
        _vm._v(" "),
        _vm.participant.activity
          ? _c("div", [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("trans")(
                      "form.recap.activities." +
                        _vm.findByActivity(_vm.participant.activity.id).uid
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _vm.findByActivity(_vm.participant.activity.id).uid ==
              "stockholm-visit"
                ? _c("br")
                : _vm._e(),
              _vm._v(" "),
              _vm.findByActivity(_vm.participant.activity.id).uid !==
              "no-activity"
                ? _c("span", [
                    _vm._v(
                      "(" +
                        _vm._s(
                          Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(
                            _vm.findByActivity(_vm.participant.activity.id)
                              .price.priceInCents / 100
                          )
                        ) +
                        " TTC)  "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }