var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDisplayed
    ? _c("div", [
        _c("div", { staticClass: "add-button" }, [
          _c("p", { staticClass: "add-button-text" }, [_vm._t("default")], 2),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.addParticipant({})
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticStyle: { "enable-background": "new 0 0 357 357" },
                  attrs: {
                    version: "1.1",
                    id: "Capa_1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    x: "0px",
                    y: "0px",
                    width: "50px",
                    height: "50px",
                    viewBox: "0 0 357 357",
                    "xml:space": "preserve",
                  },
                },
                [
                  _c("g", [
                    _c("g", { attrs: { id: "add" } }, [
                      _c("path", {
                        attrs: {
                          fill: "#fff",
                          d: "M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z",
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }