import {mapActions, mapGetters} from 'vuex';
import Vue from "vue/dist/vue";
import GuestCollection from "./GuestCollection";
import IdentityDocumentForm from "./IdentityDocumentForm";
import TransportForm from "./TransportForm";
import ParticipationPackForm from "./ParticipationPackForm";
import {updateState} from "../../../../../common/Vue/VueHelper";

export default Vue.component('ParticipantForm', {
    /**
     * Local properties of the components.
     * They are obtained from the Vue template.
     *
     * If you define a "user" property, then in your template, you should have:
     * <my-component :user="..."> [...] </my-component>
     *
     * @property {array}
     */
    props: ['participant', 'index'],

    /**
     * Vue components inside this one.
     *
     * @property {object}
     */
    components: {
        GuestCollection,
        IdentityDocumentForm,
        TransportForm,
        ParticipationPackForm
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapGetters({
            getCompleteName: 'participantCollection/getCompleteName',
            getTypeLabel: 'participantCollection/getTypeLabel'
        }),

        /**
         * Get the UIDX of the participant.
         *
         * @returns {string}
         */
        uidx() {
            return this.participant.uidx;
        },

        /**
         * Get the type of the participant.
         *
         * @returns {string}
         */
        type() {
            return this.participant.type;
        },

        /**
         * Get the civility of the participant.
         *
         * @returns {?string}
         */
        civility() {
            return this.participant.civility;
        },

        /**
         * Get the last name of the participant.
         *
         * @returns {?string}
         */
        lastName() {
            return this.participant.lastName;
        },

        /**
         * Get the first name of the participant.
         *
         * @returns {?string}
         */
        firstName() {
            return this.participant.firstName;
        },

        dateOfBirth() {
            return this.participant.dateOfBirth;
        },

        /**
         * Get the email address of the participant.
         *
         * @returns {?string}
         */
        email() {
            return this.participant.email;
        },

        /**
         * Get the mobile number of the participant.
         *
         * @returns {?string}
         */
        mobileNumber() {
            return this.participant.mobileNumber;
        },

        /**
         * Get the remarks of the participant.
         *
         * @returns {?string}
         */
        remarks() {
            return this.participant.remarks;
        },

        /**
         * Get the activity of the participant.
         *
         * @returns {?string}
         */
        activity() {
            return this.participant.activity;
        },

        comments() {
            return this.participant.comments;
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions('participantCollection', ['update', 'remove']),

        getActivity() {
            if (this.activity !== null) {
                return this.activity.id;
            }

            return null
        },

        /**
         * Get the value from the event, then pass it to the update action.
         *
         * @param {string} prop
         * @param {Event} event
         */
        updateState(prop, event) {
            updateState(this.participant, prop, event, this.update);
        }
    }
})

