import axios from "axios";

export default {
    async participation_price() {
        return axios.get('/api/participant/participation-price/', {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json'
            }
        }).then(response => response.data);
    }
}