var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDisplayed()
    ? _c("div", { staticClass: "recap-block-participant-transport" }, [
        _c("h5", [
          _vm._v(
            _vm._s(
              _vm._f("trans")(
                "form.recap.participant-accompagnant-commons.transport.title"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _vm.transport.wantShuttle
          ? _c("div", [
              _vm._v(
                _vm._s(
                  _vm._f("trans")(
                    "form.recap.participant-accompagnant-commons.transport.want-shuttle"
                  )
                ) + "\n  "
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }