import Vue from "vue/dist/vue";
import {mapActions, mapState} from "vuex";
import {updateState} from "../../../../../common/Vue/VueHelper";

export default Vue.component('BillingContactForm', {
    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            billingContact: state => state.billing_contact
        }),

        /**
         * Get the civility of the billing contact.
         *
         * @returns {?string}
         */
        civility() {
            return this.billingContact.civility;
        },

        /**
         * Get the last name of the billing contact.
         *
         * @returns {?string}
         */
        lastName() {
            return this.billingContact.lastName;
        },

        /**
         * Get the first name of the billing contact.
         *
         * @returns {?string}
         */
        firstName() {
            return this.billingContact.firstName;
        },

        /**
         * Get the address of the billing contact.
         *
         * @returns {?string}
         */
        address() {
            return this.billingContact.address;
        },

        /**
         * Get the zip code of the billing contact.
         *
         * @returns {?string}
         */
        zipCode() {
            return this.billingContact.zipCode;
        },

        /**
         * Get the city of the billing contact.
         *
         * @returns {?string}
         */
        city() {
            return this.billingContact.city;
        },

        /**
         * Get the email address of the billing contact.
         *
         * @returns {null}
         */
        email() {
            return this.billingContact.email;
        },

        /**
         * Get the phone number of the billing contact.
         *
         * @returns {?string}
         */
        phoneNumber() {
            return this.billingContact.phoneNumber;
        },

        /**
         * Get the mobile number of the billing contact.
         *
         * @returns {?string}
         */
        mobileNumber() {
            return this.billingContact.mobileNumber;
        },

        /**
         * Get the mobile number of the billing contact.
         *
         * @returns {?string}
         */
        corporateName() {
            return this.billingContact.corporateName;
        },

        agencyName() {
            return this.billingContact.agencyName;
        },

        registrationRepresentantEmail() {
            return this.billingContact.registrationRepresentantEmail;
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions({
            update: 'billing_contact/update'
        }),

        /**
         * Get the value from the event, then pass it to the update action.
         *
         * @param {string} prop
         * @param {Event} event
         */
        updateState(prop, event) {
            updateState(this.billingContact, prop, event, this.update);
        }
    },

    /**
     * Executed when each time the component is created.
     *
     * @method
     * @returns {void}
     */
    created() {
        this.$store.dispatch('billing_contact/load');
    }
})

