<template>
  <div v-if="isDisplayed()" class="recap-block-participant-activities">
    <h5>Activité :</h5>

    <div v-if="participant.activity">
      <span>{{ 'form.recap.activities.' + findByActivity(participant.activity.id).uid|trans() }}</span> <br v-if="findByActivity(participant.activity.id).uid == 'stockholm-visit'"> <span v-if="findByActivity(participant.activity.id).uid !== 'no-activity'">({{ Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(findByActivity(participant.activity.id).price.priceInCents / 100) }} TTC)  </span>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  props: ['participant'],

  /**
   * All computed properties of the component.
   *
   * @property {object}
   */
  computed: {
    /*
     * Import properties of states:
     *
     * ...mapState(['namespace/property'])
     * ...mapState('namespace', ['property', 'property'])
     * ...mapState({
     *     propertyAlias: state => state.namespace.property,
     *     propertyAlias: state => state.namespace.property
     * })
     *
     * Then use them:
     * - in Vue templates: {{ property }} or {{ propertyAlias }}
     * - in here: this.property or this.propertyAlias
     *
     * Import getters of modules:
     *
     * ...mapGetters(['namespace/getter'])
     * ...mapGetters('namespace', ['getter', 'getter'])
     * ...mapGetters({
     *     getterAlias: 'namespace/getter',
     *     getterAlias: 'namespace/getter'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
     * - in here: this.getter() or this.getterAlias()
     */
    ...mapGetters({
      getByParticipantUidx: 'activity/getByParticipantUidx',
      findByActivity: 'availabilities/findByActivity',
    }),
  },

  /**
   * All methods of the component.
   *
   * @property {object}
   */
  methods: {
    /*
     * Import actions of modules:
     *
     * ...mapActions(['namespace/action'])
     * ...mapActions('namespace', ['action', 'action'])
     * ...mapActions({
     *     actionAlias: 'namespace/action',
     *     actionAlias: 'namespace/action'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ action() }} or {{ actionAlias() }}
     * - in here: this.action() or this.actionAlias()
     *
     * Import mutations of modules:
     *
     * ...mapMutations(['namespace/mutation'])
     * ...mapMutations('namespace', ['mutation', 'mutation'])
     * ...mapMutations({
     *     mutationAlias: 'namespace/mutation',
     *     mutationAlias: 'namespace/mutation'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
     * - in here: this.mutation() or this.mutationAlias()
     */
    isDisplayed() {
      return this.participant.activity ? true : false;
    }
  }
};
</script>
