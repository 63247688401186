import axios from "axios";

export default {
    async all() {
        return axios.get('/api/activity/', {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json'
            }
        }).then(response => response.data);
    }
}
