<template>
    <div>
        <div class="recap-block-rooms">
            <h4>{{ 'form.recap.rooming.title'|trans({'%nb%': rooms.length}) }}</h4>

            <div class="recap-block-room" v-for="(room, index) in rooms" :class="{'first-child': index === 0}">
                <div class="recap-block-room-wrapper">

                    <div class="recap-block-room-number bold">
            <span class="recap-bold clickable"
                  :inner-html.prop="'form.recap.rooming.label.default'|trans({'%n%': (index + 1)})"
                  @click="scroll(getAnchor(room))"></span>
                    </div>

                    <div class="recap-block-room-complete-infos">

                        <div v-if="isEditMode">
                            <span class="recap-bold">Type d'hébergement :</span>
                            <span>{{ (!room.isAskingForPremium) ? 'Hébergement classique' : 'Hébergement Premium / Deluxe' }}</span>
                        </div>

                        <div v-if="hasMoreThanOneHotel"
                             :inner-html.prop="'form.recap.rooming.hotel'|trans({'%hotel%': getHotelLabel(findHotelById(room.hotelId))})"></div>
                        <div>
                            <span
                                :inner-html.prop="'form.recap.rooming.room-type'|trans({'%roomType%': getRoomTypeLabel(findRoomTypeById(room.roomTypeId), {withDetails: true})})"></span>
<!--                            <span> ({{ writeRoomPrice(room) }}) </span>-->
                        </div>
                        <div :inner-html.prop="'form.recap.rooming.occupants.label'|trans()"></div>
                        <div class="recap-block-room-list-occupants"
                             :inner-html.prop="'form.recap.rooming.occupants.list'|trans({'%occupants%': getOccupantsList(room)})"></div>

                        <div v-if="displayCompleteInformation">
                            <!-- ... --->
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {scroll} from "../../../../../common/functions";

export default {
    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            registration: state => state.registration,
            rooms: state => state.roomCollection.all
        }),
        ...mapGetters({
            // From module participants.
            getParticipantByUidx: 'participantCollection/getParticipantByUidx',
            getParticipantCompleteName: 'participantCollection/getCompleteName',

            // From module roomTypes.
            findRoomTypeById: 'roomTypes/findById',
            getRoomTypeLabel: 'roomTypes/getLabel',

            // From module hotels.
            findHotelById: 'hotels/findById',
            getHotelLabel: 'hotels/getLabel',
            hasMoreThanOneHotel: 'hotels/hasMoreThanOne'
        }),

        displayCompleteInformation() {
            return this.$parent.displayCompleteInformation;
        },

        isEditMode() {
            return (this.registration.id !== null);
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */

        scroll,

        price(priceInCents) {
            return Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR'
            }).format(priceInCents / 100);
        },

        /**
         * Get the anchor ID of the room.
         *
         * @param {object} room
         * @returns {string}
         */
        getAnchor(room) {
            return 'room-' + room.idx;
        },
        /**
         * Get the list of occupants of the room separated by a comma.
         *
         * @param {object} room
         * @returns {string}
         */
        getOccupantsList(room) {
            return room.occupantsUidx.map(o => this.getParticipantCompleteName(this.getParticipantByUidx(o))).join('<br>');
        },

        writeRoomPrice(room) {
            const roomType = this.findRoomTypeById(room.roomTypeId);

            let result = this.price(roomType.price.priceInCents) + ' TTC';

            if (roomType.supplement.priceInCents > 0) {
                result += ' + supplément ' + this.price(roomType.supplement.priceInCents) + ' TTC';
            }

            return result;
        }
    }
};
</script>
