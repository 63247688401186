import Vue from "vue";
import activityApi from "../../api/activity";
import participation_price from "../../api/participation_price";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * Use the VuexState#create method to define your state:
     *     VuexState.create({ ... })
     *
     * The "create" static method needs one object argument, containing all property names as keys,
     * and property object as values.
     *
     * Property objects should have a "type" property. They could also have two facultative properties:
     * - "formatter", which must have the name of one of the methods of the FieldFormatter class.
     * - "default", which is the default value of the property.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        hotelRoomType: [],
        activities: [],
        participationPrices: []
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        /**
         * Get availabilities by hotel.
         *
         * @param {object} state
         * @returns {function}
         */
        getByHotel: (state) => (hotelId) => {
            if (typeof hotelId === 'object') {
                hotelId = hotelId.id;
            }

            return state.hotelRoomType.filter(p => (p.hotelId === hotelId));
        },
        /**
         * Get total availabilities by hotel.
         * If the result is null, then it means hotel has an infinite total.
         *
         * @param {object} state
         * @param {object} getters
         * @param rootState
         * @param rootGetters
         * @returns {function}
         */
        getTotalByHotel: (state, getters, rootState, rootGetters) => (hotelId) => {
            const
                availabilities = getters.getByHotel(hotelId),
                hasInfiniteTotal = (availabilities.filter(p => (p.availabilities === null)).length > 0)
            ;

            if (hasInfiniteTotal) {
                return null;
            }

            return availabilities.reduce((prev, current) => {
                const takenByRooms = rootGetters["roomCollection/getByHotelAndRoomType"](current.hotelId, current.roomTypeId).length;

                return (prev + current.availabilities - takenByRooms);
            }, 0);
        },
        /**
         * Find availabilities by hotel and room type.
         *
         * @param {object} state
         * @param getters
         * @param rootState
         * @param rootGetters
         * @returns {function}
         */
        findByHotelAndRoomType: (state, getters, rootState, rootGetters) => (hotelId, roomTypeId) => {
            if (typeof hotelId === 'object') {
                hotelId = hotelId.id;
            }

            if (typeof roomTypeId === 'object') {
                roomTypeId = roomTypeId.id;
            }

            const
                takenByRooms = rootGetters["roomCollection/getByRoomType"](roomTypeId).length,
                found = state.hotelRoomType.find(p => (p.hotelId === hotelId && p.roomTypeId === roomTypeId)) || null
            ;

            return (found.availabilities !== null) ? (found.availabilities - takenByRooms) : null;
        },

        findByActivity: (state) => (activityId) => {

            const found = state.activities.filter(p => ( p.id == activityId ));

            if (found.length === 0){
                return null
            }

            return found[0];
        },

        findParticipationPrice: (state) => () => {
            return state.participationPrices[0];
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        /**
         * Load data from API.
         *
         * @param {object} rootState
         * @param {object} state
         * @param {function} commit
         * @returns {Promise}
         */
        load({rootState, state, commit, dispatch}) {
            return new Promise(async resolve => {
                commit('reset');

                for (const pivot of rootState.data.hotelRoomTypePivot) {
                    const data = {
                        hotelRoomType: state.hotelRoomType.filter(() => true)
                    };

                    data.hotelRoomType.push({
                        hotelId: pivot.hotel.id,
                        roomTypeId: pivot.roomType.id,
                        availabilities: pivot.availabilities
                    });

                    commit('update', {data});
                }

                await dispatch('loadActivities');

                await dispatch('loadParticipationPrice');

                return resolve();
            });
        },

        async loadActivities({commit}) {
            const response = await activityApi.all();

            commit('resetActivities');
            commit('updateActivities', response.data);
        },

        async loadParticipationPrice({commit}) {
            const response = await participation_price.participation_price();

            commit('resetParticipationPrice');
            commit('updateParticipationPrice', response.data);
        },
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        update(state, {data}) {
            for (const prop in data) {
                Vue.set(state, prop, data[prop]);
            }
        },
        reset(state) {
            state.hotelRoomType = [];
        },
        updateActivities(state, activities) {
            for (const activity of activities) {
                state.activities.push(activity);
            }
        },
        resetActivities(state) {
            Vue.set(state, 'activities', []);
        },
        updateParticipationPrice(state, participationPrices) {
            for (const participationPrice of participationPrices) {
                state.participationPrices.push(parseInt(participationPrice));
            }
        },
        resetParticipationPrice(state) {
            Vue.set(state, 'participationPrices', []);
        },
    }
}
