<template>
  <div v-if="isDisplayed()" class="recap-block-participant-transport">
    <h5>{{ 'form.recap.participant-accompagnant-commons.transport.title'|trans }}</h5>

    <div v-if="transport.wantShuttle">{{
        'form.recap.participant-accompagnant-commons.transport.want-shuttle'|trans
      }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['transport'],

  /**
   * All computed properties of the component.
   *
   * @property {object}
   */
  computed: {
    /*
     * Import properties of states:
     *
     * ...mapState(['namespace/property'])
     * ...mapState('namespace', ['property', 'property'])
     * ...mapState({
     *     propertyAlias: state => state.namespace.property,
     *     propertyAlias: state => state.namespace.property
     * })
     *
     * Then use them:
     * - in Vue templates: {{ property }} or {{ propertyAlias }}
     * - in here: this.property or this.propertyAlias
     *
     * Import getters of modules:
     *
     * ...mapGetters(['namespace/getter'])
     * ...mapGetters('namespace', ['getter', 'getter'])
     * ...mapGetters({
     *     getterAlias: 'namespace/getter',
     *     getterAlias: 'namespace/getter'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
     * - in here: this.getter() or this.getterAlias()
     */
  },

  /**
   * All methods of the component.
   *
   * @property {object}
   */
  methods: {
    /*
     * Import actions of modules:
     *
     * ...mapActions(['namespace/action'])
     * ...mapActions('namespace', ['action', 'action'])
     * ...mapActions({
     *     actionAlias: 'namespace/action',
     *     actionAlias: 'namespace/action'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ action() }} or {{ actionAlias() }}
     * - in here: this.action() or this.actionAlias()
     *
     * Import mutations of modules:
     *
     * ...mapMutations(['namespace/mutation'])
     * ...mapMutations('namespace', ['mutation', 'mutation'])
     * ...mapMutations({
     *     mutationAlias: 'namespace/mutation',
     *     mutationAlias: 'namespace/mutation'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
     * - in here: this.mutation() or this.mutationAlias()
     */
    isDisplayed() {
      return this.transport.wantShuttle;
    }
  }
};
</script>
