var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.registration.isParticipating
    ? _c("div", { staticClass: "recap-block" }, [
        _c("div", { staticClass: "recap-block-header" }, [
          _c("h3", [_vm._v(_vm._s(_vm._f("trans")("form.recap.title")))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "recap-block-content" }, [
          _c("div", { staticClass: "recap-block-container" }, [
            _c("p", { staticClass: "recap-block-date" }, [
              _vm.agency.name === null
                ? _c("span", {
                    domProps: {
                      innerHTML: _vm._f("trans")("form.recap.date.default", {
                        "%date%": new Date().toLocaleDateString("fr"),
                      }),
                    },
                  })
                : _vm.agency.name
                ? _c("span", {
                    domProps: {
                      innerHTML: _vm._f("trans")("form.recap.date.withAgency", {
                        "%date%": new Date().toLocaleDateString("fr"),
                        "%agency%": _vm.agency.name,
                      }),
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "recap-block-content" },
              [
                _vm.agency.name && _vm.displayCompleteInformation
                  ? _c("div", { staticClass: "recap-block-agency" }, [
                      _c("div", { staticClass: "recap-block-agency-wrapper" }, [
                        _c("div", { staticClass: "recap-block-agency-title" }, [
                          _c("div", { staticClass: "bold" }, [
                            _c("span", { staticClass: "recap-bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("trans")("form.recap.agency.title")
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "recap-block-agency-complete-infos" },
                          [
                            _vm.agency.name
                              ? _c("div", {
                                  staticClass: "recap-block-agency-name",
                                  domProps: {
                                    innerHTML: _vm._f("trans")(
                                      "form.recap.agency.name",
                                      { "%name%": _vm.agency.name }
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.agency.type
                              ? _c("div", {
                                  staticClass: "recap-block-agency-type",
                                  domProps: {
                                    innerHTML: _vm._f("trans")(
                                      "form.recap.agency.type",
                                      {
                                        "%type%": _vm.trans(
                                          "entity.agency.type." +
                                            _vm.agency.type +
                                            ".label"
                                        ),
                                      }
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.participants.length > 0
                  ? _c("form-recap-participant")
                  : _vm._e(),
                _vm._v(" "),
                _vm.rooms.length > 0 ? _c("form-recap-room") : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "total-registration-price" }, [
                  _c("h3", [
                    _vm._v(
                      "TOTAL de la commande = " +
                        _vm._s(
                          Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          }).format(_vm.getRegistrationTTCTotal())
                        ) +
                        " TTC"
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }