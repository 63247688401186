<template>
    <div>
        <div class="overlay-modal" v-show="isOpen" @click="close"></div>

        <div class="room-modal" v-show="isOpen">
            <div class="room-modal-header">
                <div class="room-modal-fixed-title">
                    <h4 class="u-txt-uppercase">{{ 'form.rooming.modal.title' | trans }}</h4>
                </div>

                <button type="button" class="room-modal-close" @click="close">
                    <svg fill="none" height="33" viewBox="0 0 24 24" width="33" xmlns="http://www.w3.org/2000/svg">
                        <path d="m16 8-8 8m.00001-8 7.99999 8" stroke="#000" stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5"/>
                    </svg>
                </button>
            </div>

            <div class="room-modal-content">
                <p class="help" :inner-html.prop="'form.rooming.modal.help-multiple'|trans"
                   v-if="hasMoreThanOneHotel"></p>
                <p class="help" :inner-html.prop="'form.rooming.modal.help'|trans" v-else></p>


                <div class="select-label">
                    <label>{{ 'form.rooming.occupants' | trans }} :</label>
                </div>
                <v-select
                    multiple
                    :placeholder="'form.rooming.modal.occupants.select.placeholder'|trans"
                    :options="getSelectableParticipants()"
                    :selectable="isSelectable"
                    :value="occupants"
                    label="_vSelectLabel"
                    @option:selecting="selectParticipant"
                    @option:deselecting="deselectParticipant">

                    <template v-slot:option="option">{{ getCompleteName(option) }}</template>

                    <template #no-options>{{ 'form.rooming.modal.occupants.select.noMoreOccupant'|trans }}</template>
                </v-select>

                <div class="room-modal-premium" v-if="isFormEditMode">
                    <label>
                        <b>Type d'hébergement :</b>
                    </label>

                    <div class="form-item-choice-container">
                        <div class="form-item-choice">
                            <input
                                id="is-asking-for-premium-0"
                                type="radio"
                                name="is-asking-for-premium"
                                value="0"

                                :checked="isAskingForPremium === false"
                                @change="selectIsAskingForPremium"
                            >

                            <label for="is-asking-for-premium-0">Hébergement classique</label>
                        </div>

                        <div class="form-item-choice">
                            <input id="is-asking-for-premium-1"
                                   type="radio"
                                   name="is-asking-for-premium"
                                   value="1"

                                   :checked="isAskingForPremium"
                                   @change="selectIsAskingForPremium"
                            >

                            <label for="is-asking-for-premium-1">Hébergement Premium / Deluxe</label>
                        </div>
                    </div>

                    <div class="room-modal-premium-message">
                        <p>
                            <span class="text-upp">Chambre Classique :</span>
                            18 - 24m<sup>2</sup>, 1 lit Queen size de 160 ou deux lits de 90 collés.
                        </p>
                    </div>

                    <div class="room-modal-premium-message" v-if="isAskingForPremium">
                        <p class="bold text-upp">Supplément tarifaire :</p>

                        <p>
                            <span class="text-upp">Chambre <b>Premium</b> :</span>
                            24m<sup>2</sup>, lits de 180 cm<br>
                            Tarif : à partir de 68€ de supplément par nuitée en single, et 77€ de supplément par nuitée en double et twin.<br>
                            Sur demande et sous réserve de disponibilité au moment de la confirmation.
                        </p>

                        <p>
                            <span class="text-upp">Chambre <b>Deluxe</b> :</span>
                            26 - 30 m<sup>2</sup><br>
                            Disponible uniquement en chambre double (1 lit pour deux personnes) ou en single (1 grand lit de 180cm).<br>
                            Tarif : à partir de 85€ de supplément par nuitée en single, et 94€ de supplément par nuitée en double (<b>catégorie de chambre non disponible en twin</b>).<br>
                            Sur demande et sous réserve de disponibilité au moment de la confirmation.
                        </p>

                        <p style="margin-top: 30px;">
                            <b>
                                En cochant cette case, vous faites une demande d'information pour un hébergement Premium
                                / Deluxe.<br>
                                Vous recevrez un mail avec les disponibilités à jour.
                            </b>
                        </p>

                        <p>
                            Les demandes de chambres "<b>Premium</b>" et "<b>Deluxe</b>" seront satisfaites sous réserve
                            de disponibilités jusqu'à la validation par mail de l'agence Ici La Terre.<br>
                            En cas d'indisponibilité, une chambre classique est confirmée à la réservation.
                        </p>

                        <p>
                            Le supplément pour un hébergement supérieur (<b>Premium / Deluxe</b>) fera l'objet d'une
                            facturation complémentaire (hors inscription à la convention).
                        </p>

                        <p>
                            Pour toute question, merci de contacter <span class="bold">{{
                                ske().parameters.siteReferentCompleteName
                            }}</span> au

                            <a :href="'tel:' + ske().parameters.siteReferentPhoneNumber">
                                {{ ske().parameters.siteReferent['phone-number'] }}
                            </a>

                            ou par mail à

                            <a :href="'mailto:' + ske().parameters.siteReferentEmail">
                                {{ ske().parameters.siteReferentEmail }}
                            </a>.
                        </p>
                    </div>
                </div>

                <div class="room-modal-hotels" v-if="hasMoreThanOneHotel">
                    <label>{{ 'form.rooming.modal.hotel.label'|trans }}</label>

                    <select name="hotel" @change="selectHotel">
                        <option :selected="hotel === null" disabled>{{
                                'form.rooming.modal.hotel.placeholder'|trans
                            }}
                        </option>

                        <option v-for="hotelChoice in hotels"
                                :value="hotelChoice.id"
                                :selected="hotel && hotelChoice.id === hotel.id"
                                :disabled="hotelIsDisabled(hotelChoice)">
                            {{ getHotelLabel(hotelChoice) }}
                        </option>
                    </select>
                </div>

                <div class="room-form">
                    <div class="form-item typeOfRoom" v-if="roomTypes">
                        <label>{{ 'form.rooming.modal.room-type.label'|trans }}</label>

                        <div v-for="roomTypeChoice in roomTypes">
                            <div class="form-item-choice">
                                <input type="radio" name="room-type"
                                       :value="roomTypeChoice.id"
                                       :id="'room-type' + roomTypeChoice.id"
                                       :checked="roomType && roomTypeChoice.id === roomType.id"
                                       :disabled="isRoomTypeDisabled(roomTypeChoice)"
                                       @change="selectRoomType">

                                <label :for="'room-type' + roomTypeChoice.id">
                                    {{ getRoomTypeLabel(roomTypeChoice, {withDetails: true}) }}

                                    <!--                      <span v-if="+availabilities(hotel.id, roomTypeChoice.id) !== null">-->
                                    <span v-if="roomTypeChoice.price.priceInCents > 0">
                              <span>
                                  {{
                                      'form.rooming.modal.room-type.prix' | trans({
                                          '%prix%': Intl.NumberFormat('de-DE', {
                                              style: 'currency',
                                              currency: 'EUR'
                                          }).format(roomTypeChoice.price.priceInCents / 100)
                                      })
                                  }}
                              </span>
                      </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- div class="room-form room-supplement">
                  <div class="form-item typeOfRoom" v-if=" findRoomType() == 'single' ">
                    <label>{{ 'form.rooming.modal.supplement.label'|trans }}</label>
                    <p>
                      {{ 'form.rooming.modal.supplement.body'|trans({
                          '%prix%': Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(roomType.supplement.priceInCents / 100)
                      }) }}
                    </p>
                  </div>
                </div -->

                <div class="room-submit-container">
                    <button type="button" class="submit c-button c-button-secondary" @click="submit"
                            :disabled="isSubmitDisabled()">
                        {{ 'form.rooming.modal.submit'|trans }}
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select/dist/vue-select'
import {mapState, mapGetters, mapActions} from 'vuex'
import $ from "jquery"
import {ske} from "../../../../../common/Ske/Ske";

export default {
    /**
     * Vue components inside this one.
     *
     * @property {object}
     */
    components: {
        vSelect
    },

    /**
     * Local data of the components.
     *
     * @property {array}
     */
    data: function () {
        return {
            isOpen: false,
            occupants: [],
            roomType: null,
            hotel: null,
            isAskingForPremium: null,
            idx: null,
            baseRoom: null
        }
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            registration: state => state.registration,
            roomTypes: state => state.roomTypes.all,
            hotels: state => state.hotels.all,
            participants: state => state.participantCollection.all
        }),
        ...mapGetters({
            // From module participant.
            getParticipantByUidx: 'participantCollection/getParticipantByUidx',
            participantsWithoutRoom: 'participantCollection/getParticipantsWithoutRoom',
            getCompleteName: 'participantCollection/getCompleteName',
            getCompleteNameTwo: 'participantCollection/getCompleteNameTwo',

            // From module rooming.
            findRoomByIdx: 'roomCollection/findRoomByIdx',
            getRoomOccupants: 'roomCollection/getOccupants',

            // From module roomTypes.
            findRoomTypeById: 'roomTypes/findById',
            getRoomTypeLabel: 'roomTypes/getLabel',

            // From module hotels.
            findHotelById: 'hotels/findById',
            hasMoreThanOneHotel: 'hotels/hasMoreThanOne',
            getRoomTypes: 'hotels/getRoomTypes',
            hotelHasRoomTypeId: 'hotels/hasRoomTypeId',
            getHotelLabel: 'hotels/getLabel',

            // From module availabilities.
            getAvailabilities: 'availabilities/findByHotelAndRoomType',
            getTotalAvailabilitiesByHotel: 'availabilities/getTotalByHotel'
        }),

        isFormEditMode() {
            return (this.registration.id !== null);
        },

        roomTypes() {
            if (this.hotel === null) {
                return null;
            }

            return this.getRoomTypes(this.hotel);
        },

        /**
         * Tell whether we're editing an existing room or not.
         *
         * @returns {boolean}
         */
        isEditing() {
            return (this.idx !== null);
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        ske,
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions('roomCollection', ['add', 'update']),

        availabilities(hotelId, roomTypeId) {
            let availabilities = this.getAvailabilities(hotelId, roomTypeId);

            // If availabilities is null, so there is an infinite.
            if (availabilities === null) {
                return null;
            }

            // If it's the selected room type, we decrease the availabilities.
            if (this.roomType?.id === roomTypeId) {
                availabilities--;
            }

            if (this.isEditing) {
                // If the selected room type and hotel are the same as before editing, we increase the availabilities.
                if (hotelId === this.baseRoom.hotelId && roomTypeId === this.baseRoom.roomTypeId) {
                    availabilities++;

                    // If the room was already saved into the DB, then we increase once more.
                    if (this.baseRoom.id !== null) {
                        availabilities++;
                    }
                }
            }

            return availabilities;
        },

        /**
         * Get the participants that are available inside the dropdown.
         * Participants which have already a room are not included.
         *
         * @returns {array}
         */
        getSelectableParticipants() {
            const participants = this.participantsWithoutRoom;

            /*if (this.isEditing) {
                const room = this.findRoomByIdx(this.idx);

                for (const occupant of this.getRoomOccupants(room)) {
                    participants.push(occupant);
                }
            }*/

            let selectableParticipants = [];

            for (const participant of participants) {
                const isAlreadyOccupant = (this.occupants.findIndex(o => (o.uidx === participant.uidx)) >= 0);

                if (!isAlreadyOccupant && this.getCompleteNameTwo(participant) !== null) {
                    selectableParticipants.push(participant);
                }
            }

            return selectableParticipants;
        },

        /**
         * Tell whether we can continue to select more participants.
         *
         * @returns {boolean}
         */
        isSelectable() {
            return true;
        },

        /**
         * Fired when a participant is selected as occupant.
         *
         * @param {object} occupant
         * @returns {void}
         */
        selectParticipant(occupant) {

            this.occupants.push(occupant);

            if (this.isRoomTypeDisabled(this.roomType)) {
                this.roomType = null;
            }
        },

        /**
         * Fired when a participant is removed from a room.
         *
         * @param {object} occupant
         * @returns {void}
         */
        deselectParticipant(occupant) {
            const index = this.occupants.findIndex(o => (o.idx === occupant.idx));

            this.occupants.splice(index, 1);

            if (this.isRoomTypeDisabled(this.roomType)) {
                this.roomType = null;
            }
        },

        /**
         *
         * @param {object} hotel
         * @returns {boolean}
         */
        hotelIsDisabled(hotel) {
            if (this.baseRoom?.hotelId === hotel.id) {
                return false;
            }

            const total = this.getTotalAvailabilitiesByHotel(hotel);

            return (total === null) ? false : (total === 0);
        },

        /**
         * Fired when a hotel is selected.
         *
         * @param {Event} event
         * @returns {void}
         */
        selectHotel(event) {
            this.hotel = this.findHotelById($(event.target).val());

            if (this.roomType) {
                if (!this.hotelHasRoomTypeId(this.hotel, this.roomType.id) || this.availabilities(this.hotel.id, this.roomType.id) <= 0) {
                    this.roomType = null;
                }
            }
        },

        /**
         * Tell whether a room type is disabled or not.
         *
         * @param {?object} roomType
         * @returns {boolean}
         */
        isRoomTypeDisabled(roomType = null) {
            // Will be disabled if no room type given.
            if (roomType === null) {
                return true;
            }

            // Will not be disabled if it's the current selected room type.
            if (this.roomType?.id === roomType.id) {
                return false;
            }

            const
                nbPersonsNotCorrect = (ske().registrationModule.roomingModule.isRoomTypeSelectionStrict)
                    ? (roomType.nbPersons !== this.occupants.length) : (roomType.nbPersons < this.occupants.length),
                availabilities = this.availabilities(this.hotel.id, roomType.id),
                hasInfiniteAvailabilities = (availabilities === null),
                hasEnoughAvailabilities = (availabilities > 0)
            ;

            return (nbPersonsNotCorrect || (!hasEnoughAvailabilities && !hasInfiniteAvailabilities));
        },

        /**
         * Fired when a room type is selected.
         *
         * @param {Event} event
         * @returns {void}
         */
        selectRoomType(event) {
            this.roomType = this.findRoomTypeById($(event.target).val());
        },

        selectIsAskingForPremium(event) {
            this.isAskingForPremium = (+event.target.value === 1);
        },

        /**
         * Tell whether the submit button is disabled or not.
         *
         * @returns {boolean}
         */
        isSubmitDisabled() {
            return (this.hotel === null || this.roomType === null || this.occupants.length === 0);
        },

        /**
         * Fired when the submit button is clicked.
         *
         * @returns {void}
         */
        submit() {
            const room = {
                roomTypeId: this.roomType.id,
                hotelId: this.hotel.id,
                isAskingForPremium: this.isAskingForPremium,
                occupantsUidx: this.occupants.map(o => o.uidx)
            };

            if (!this.isEditing) {
                this.add({data: room});
            } else {
                this.update({
                    data: {
                        ...room,
                        idx: this.idx
                    }
                });
            }

            this.close();
        },

        /**
         * Reset all data of the component.
         * The data are prefilled if an existing room is given.
         *
         * @param {object} room
         * @returns {void}
         */
        reset(room = null) {
            if (room === null) {
                this.roomType = null;
                this.hotel = (this.hasMoreThanOneHotel) ? null : this.hotels[0];
                this.isAskingForPremium = null;
                this.occupants = [];
                this.idx = null;
                this.baseRoom = null;
            } else {
                this.roomType = this.findRoomTypeById(room.roomTypeId);
                this.hotel = this.findHotelById(room.hotelId);
                this.isAskingForPremium = room.isAskingForPremium;
                this.occupants = room.occupantsUidx.map(uidx => this.getParticipantByUidx(uidx));
                this.idx = room.idx;
                this.baseRoom = room;
            }
        },

        /**
         * Open the modal.
         *
         * @param {object} room
         * @returns {void}
         */
        open(room = null) {
            this.reset(room);
            this.isOpen = true;
        },

        /**
         * Close the modal.
         *
         * @returns {void}
         */
        close() {
            this.isOpen = false;
        },

        findRoomType() {
            if (this.roomType == null) {
                return 'none';
            }

            if (this.roomType.uid == null) {
                return 'none';
            }

            return this.roomType.uid
        }
    }
}
</script>
