import $ from "jquery";
import FieldFormatter from "../Formatter/FieldFormatter";

const formatters = {
    isParticipating: [FieldFormatter.boolean],

    name: [FieldFormatter.uppercase],
    corporateName: [FieldFormatter.uppercase],

    firstName: [FieldFormatter.capitalizeAll],
    lastName: [FieldFormatter.uppercase],
    mobileNumber: [FieldFormatter.phone],
    email: [FieldFormatter.email],
    zipCode: [FieldFormatter.zipCode],

    number: [FieldFormatter.uppercase],
    firstNames: [FieldFormatter.capitalizeAll],
    lastNames: [FieldFormatter.uppercase],
    nationality: [FieldFormatter.uppercase],

    wantShuttle: [FieldFormatter.boolean],

    emailCopyTo: [FieldFormatter.email],

    address: [FieldFormatter.uppercase],
    city: [FieldFormatter.uppercase],
    agencyName: [FieldFormatter.uppercase]
};

export function updateState(baseObject, prop, event, action) {
    let value = $(event.target).val();

    if (prop in formatters) {
        for (const formatter of formatters[prop]) {
            value = formatter(value);
        }
    }

    if ( prop == 'activity' ) {
        action({
            data: {
                ...baseObject,
                [prop]: {id : value}
            }
        });
    } else {
        action({
            data: {
                ...baseObject,
                [prop]: value
            }
        });
    }
}

/**
 * Generic LoadState action method.
 * Get global data, then commit them for mutation.
 *
 * @param {object} rootState
 * @param {VuexState} state
 * @param {function} commit
 * @param {string} mutation
 * @param {?string} allDataProp
 * @returns {void}
 */
function actionLoadState({rootState, state, commit}, mutation = 'updateState', allDataProp = null) {
    const
        allData = (allDataProp)
            ? $.extend(true, {}, rootState.data.data[allDataProp])
            : $.extend(true, {}, rootState.data.data),
        data = {}
    ;

    // Loop through all data properties.
    for (const prop in allData) {
        // If the property is present in the object state, then take it.
        if (prop in state) {
            data[prop] = allData[prop];
        }
    }

    // Commit the final data.
    commit(mutation, { data });
}

/**
 * Generic UpdateState action method.
 * Update all properties of a state.
 *
 * @param {function} commit
 * @param {object} data
 * @param {string} mutation
 * @returns {void}
 */
function actionUpdateState({commit}, {data, mutation = 'updateState'}) {
    commit(mutation, { data });
}

/**
 * Generic UpdateStateProperty action method.
 * Update a particular property of a state.
 *
 * @param {function} commit
 * @param {string} prop
 * @param {*} value
 * @param {string} mutation
 * @returns {void}
 */
function actionUpdateStateProperty({commit}, {prop, value, mutation = 'updateStateProperty'}) {
    commit(mutation, { prop, value });
}

/**
 * Generate a helper method for importing mutations into Vuex modules.
 * Usage: ...helperMutations(['firstFn', 'secondFn', 'thirdFn'])
 *
 * @param {array} mutations
 * @returns {object}
 */
export function helperMutations(mutations) {
    return generateHelperResult({
        updateState: mutationUpdateState,
        updateStateProperty: mutationUpdateStateProperty
    }, mutations);
}

/**
 * Generic UpdateState mutation method.
 * Update all properties of a state.
 *
 * @param {VuexState} state
 * @param {object} data
 * @returns {void}
 */
function mutationUpdateState(state, { data }) {
    state.update(data);
}

/**
 * Generic UpdateStateProperty mutation method.
 * Update a specific property of a state.
 *
 * @param {VuexState} state
 * @param {string} prop
 * @param {*} value
 * @returns {void}
 */
function mutationUpdateStateProperty(state, {prop, value}) {
    state.update({ [prop]: value });
}
