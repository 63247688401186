import Vue from "vue";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * @property {object}
     */
    state: () => ({
        _transportState: () => ({
            wantShuttle: null,

            participantUidx: null
        }),

        all: []
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        findByParticipantUidx: (state) => (participantUidx) => {
            return state.all.find(t => (t.participantUidx === participantUidx));
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        /**
         * Add a new transport.
         *
         * @param {function} commit
         * @param {object} data
         */
        add({commit}, {data = {}}) {
            commit('add', {data});
        },

        /**
         * Update an existing transport.
         *
         * @param {function} commit
         * @param {object} data
         */
        update({commit}, {data}) {
            commit('update', {data})
        },

        /**
         * Remove an existing transport.
         *
         * @param {function} commit
         * @param {number} participantUidx
         */
        remove({commit}, {participantUidx}) {
            commit('remove', {participantUidx});
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        /*
         * Here, you can import methods from the helper.
         *
         * ...helperMutations(['myMutation']),
         * or
         * ...helperMutations({
         *     otherMutationName: myMutation
         * }),
         */

        /**
         * Add a new transport.
         *
         * @param {object} state
         * @param {object} data
         */
        add(state, {data = {}}) {
            const identityDocument = {
                ...state._transportState(),
                ...data
            };

            state.all.push(identityDocument);
        },

        /**
         * Update an existing transport.
         *
         * @param {object} state
         * @param {object} data
         */
        update(state, {data}) {
            const index = state.all.findIndex(t => (t.participantUidx === data.participantUidx));

            for (const prop in data) {
                Vue.set(state.all[index], prop, data[prop]);
            }
        },

        /**
         * Delete an existing transport.
         *
         * @param {object} state
         * @param {number} participantUidx
         */
        remove(state, {participantUidx}) {
            const index = state.all.findIndex(t => (t.participantUidx === participantUidx));

            state.all.splice(index, 1);
        }
    },
}
